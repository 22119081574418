import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/contact_us.css"

const ContactUs = () => (
  <div className="contact_us">
  <SEO title="Contact us" />
    
    <div className="contact_image_wrapper about-image-wrapper">
      <div className="header-name-container">
        <h1 className="header_name">Contact Us</h1>
      </div>
      <Image filename="terrell.jpg"/>
    </div >

    <div className="outter_form_wrapper">
      <h2>Upcoming Event?</h2>
      <h2>Get In Contact with Our Team, We're excited to Help</h2>
    </div>

    <div className="other_contacts">
      <p>You can also reach us via Email and Cell</p>
      <a href="mailto:darkhorsepastries@gmail.com">darkhorsepastries@gmail.com</a>
      <a style={{display: 'block'}} href="tel:630-570-0347">630-570-0347</a>        
    </div>
    
  </div >
)
export default ContactUs
