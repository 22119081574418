import React, {Component} from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Image from "../components/image"
import Carousel from 'react-bootstrap/Carousel'
import "../assets/gallery.css"

class Gallery extends Component {

  constructor(props) {

    super(props);

    this.state = {
      entrees: false,
      apps: false,
      team: false,
      desserts: false,
    };

    this.apps = this.apps.bind(this);
    this.team = this.team.bind(this);
    this.desserts = this.desserts.bind(this);
    this.entrees = this.entrees.bind(this);
    this.everything = this.everything.bind(this);
    
  }

  everything() {
    this.setState(prevState => ({
      entrees: false,
      apps: false,
      team: false,
      desserts: false,
    }));
  }

  apps() {
    this.setState(prevState => ({
      apps: false,
      entrees: true,
      team: true,
      desserts: true,
    }));
  }

  team() {
    this.setState(prevState => ({
      team: false,
      apps: true,
      entrees: true,
      desserts: true,
    }));
  }

  desserts() {
    this.setState(prevState => ({
      desserts: false,
      apps: true,
      entrees: true,
      team: true,
    }));
  }

  entrees() {
    this.setState(prevState => ({
      entrees: false,
      apps: true,
      desserts: true,
      team: true,
    }));
  }

  render(){

    const apps = this.state.apps ? 'apps' : '';
    const entrees = this.state.entrees ? 'entrees' : '';
    const desserts = this.state.desserts ? 'desserts' : '';
    const team = this.state.team ? 'team' : '';    
    
    return(
      <div className="gallery">
          <SEO title="Gallery" />
          <div className="about-image-wrapper">
            <div className="header-name-container">
              <h1 className="header_name">Gallery</h1>
            </div>
              <Carousel indicators={false} interval={3000} touch={true} wrap={true}>
                <Carousel.Item className="carousel-images"> 
                  <Image  filename="churro.jpg" />
                </Carousel.Item>

                <Carousel.Item className="carousel-images"> 
                  <Image  filename="terrell-customer.jpg" />
                </Carousel.Item>
              </Carousel>
            </div >
          
          <div className="image_gallery_type">
            <p onClick={() => this.everything()}>Everything</p>
            <p onClick={this.team}>The Team</p>
            <p onClick={this.desserts}>Desserts</p>
            <p onClick={this.entrees}>Entrees</p>
            <p onClick={this.apps}>Appetizers</p>
          </div>
          <div className="gallery-container">
            <ul>
              <li className={`image_container ${team}`}>
                <Image  filename="terrell-customer.jpg" />
              </li>
              <li className={`image_container ${entrees}`}>
                <Image  filename="steak_onions.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="soup_2.jpg" />
              </li>
              <li className={`image_container ${desserts}`}>
                <Image  filename="churro.jpg" />
              </li>
              <li className={`image_container ${team}`}>
                <Image  filename="pizza-oven.jpeg" />
              </li>
              <li className={` desserts image_container ${apps}`}>
                <Image  filename="mac.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="bread.jpg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="potato_dish.jpg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="chicken-pasta.jpeg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="something.jpg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="shrimp_sandwhich.jpg" />
              </li>
              <li className={` image_container ${desserts}`}>
                <Image  filename="cheesecake_2.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="cut.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="cheese-fries.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="clams.jpeg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="fish_plate.jpg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="pizza_in_oven.jpg" />
              </li>
              <li className={` image_container ${entrees}`}>
                <Image  filename="ham-salad.jpeg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="soup.jpg" />
              </li>
              <li className={` image_container ${apps}`}>
                <Image  filename="mac-n-cheese.jpeg" />
              </li>
              <li className={` image_container ${desserts}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967117-b944f100-24d1-11eb-8aa2-ddc5ae6f1af9.jpeg" />
              </li>
              <li className={` image_container ${desserts}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967215-db3e7380-24d1-11eb-8613-cdc717b0c3ae.jpeg" />
              </li>
              <li className={` image_container ${desserts}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967705-6a4b8b80-24d2-11eb-9913-f51347664687.jpeg" />
              </li>
              <li className={` image_container ${apps}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967712-6cade580-24d2-11eb-9f12-2627442b00f6.jpeg" />
              </li>
              <li className={` image_container ${apps}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967762-79cad480-24d2-11eb-8e8a-8adf1a308fd4.jpeg" />
              </li>
              <li className={` image_container ${apps}`}>
                <img  src="https://user-images.githubusercontent.com/26069518/98967769-7c2d2e80-24d2-11eb-8c4f-d3d02138afc4.jpeg" />
              </li>
              </ul>
          </div>
      
        </div >
    )

  }   
}

export default Gallery
