import React ,{ useState, useEffect } from "react"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/menu.css"
import firebase from "../components/firebase"
import { useLocation } from '@reach/router';
import queryString from 'query-string';

const Menu = () => {
  
  const location = useLocation();

  const [activeMenuTitle, setActiveMenuTitle] = useState("main menu");
  const [onClick,setOnClick] = useState(false)
  
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.title) {
      const formattedTitle = params.title.replace(/-/g, ' ');
      setActiveMenuTitle(formattedTitle);
    }
    
  }, [location]);

  const handleClick = () => {
    setOnClick(state => !onClick)
  }

  const showMore = onClick ? 'show-more-menu' : '';

  const GetMenus = () => {

    const [customMenus, setCustomMenus] = useState([]); //useState() hook, sets initial state to an empty array
    
    useEffect(() => {
      const unsubscribe = firebase
      .firestore()
      .collection("customMenus")
      .onSnapshot(snapshot => {
            const listItems = snapshot.docs.map(doc => ({
                id: doc.id,
              ...doc.data(),
            }));
            setCustomMenus(listItems)
          });
          return () => unsubscribe();
        },[]);
      return customMenus;
  };

    const DisplayCustomMenus = () => {
      const [activeMenuType, setActiveMenuType] = useState("entreesmain menu");
  
      const toggleMenuType = (type) => {
          console.log(type)
          setActiveMenuType(activeMenuType === type ? null : type);
      };
  
      const toggleMenuTitle = (title) => {
        console.log(title)
        setActiveMenuTitle(activeMenuTitle === title ? null : title);
    };
  
      return <div className="menusDisplay">
        {Menus.length > 0 && 
        <div className="custom-menu" style={{listStyle: "none"}}>
            <h1>Our Menus</h1>

            <div className="menu-titles">
                {Menus.map((menu, index) => (

                <button style={{color: activeMenuTitle === menu.title ? "black" : "white", fontWeight: activeMenuTitle === menu.title ? "900" : "normal"}} className="menuTitles" onClick={() => toggleMenuTitle(menu.title)}>
                  {menu.title}
                </button>

                ))}         
              </div>

                {Menus.map((menu, index) => (
                  <div className="custom-menu-items-wrapper" key={index}>

                    <div className="menus-title-container">
                        {activeMenuTitle === menu.title && menu.menu.map((item, idx) => (
                          <div key={idx}>

                              <h1 onClick={() => toggleMenuType(item.type + menu.title)} style={{color: activeMenuType === item.type + menu.title ? "black" : "white", textDecoration: activeMenuType === item.type + menu.title ? "underline" : "none", listStyle: "numeric", textAlign: "left", cursor: "pointer", margin:"6px"}}>
                                  {item.type}
                              </h1>

                          </div>
                        ))}
                      </div>

                    <div className="menus-type-container">
                        {activeMenuTitle === menu.title && menu.menu.map((item, idx) => (
                          <div key={idx}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly"
                            }}
                          >
                            {activeMenuType === item.type + menu.title && item.menu.map((menuItem, menuIndex) => (
                                <li key={menuIndex} style={{textTransform: "capitalize",margin:"10px", background: "#8e0000", padding: "10px", color:"#fff"}}>
                                    {menuItem.name}
                                </li>
                            ))}
                          </div>
                        ))}
                      </div>
                  </div>
                ))}

            </div>
          }
        </div>
    };
    
    const Menus = GetMenus()

    return(
      <div className="menu_container">
      
        <SEO title="Menu" />

        <div className="about-image-wrapper">
          <div className="header-name-container">
            <h1 className="header_name">Catering Menu</h1>
          </div>
          <Image filename="menu-sheet.jpg"/>
        </div>
      
        <div style={{backgroundColor: "#801820"}} className="menu-container">
          <div className="display_custom_menus">
              {DisplayCustomMenus()}
 
          </div>

          <div className="menu_second_statement">
            <h2>Pick and choose</h2>
            <h2>Customize a menu for any event</h2>
            <p>
              Contact us for pricing
            </p>
            <a href="mailto:darkhorsepastries@gmail.com">darkhorsepastries@gmail.com</a>
          </div>



          {/* <ul className={`menu-type-container entrees-container ${showMore}`} >


            <h2>Entrees</h2>
            <li>
              <Image filename="grilled-wings.jpeg"/>
              <p className="food-title">SMOKED WINGS</p>
            </li>
            <li>
              <p>TACOS</p>
              <p className="ingridients">Grilled Chicken | Ground Beef | Pork</p>
            </li>
            <li>
              <p>CUCUMBER BRUSCHETTA</p>
            </li>
            <li>
              <p>CRAB BRUSCHETTA</p>
            </li>
            <li>
              <p>TOMATO BRUSCHETTA</p>
            </li>
            <li>
              <p>SMOKED SALMON BRUSCHETTA</p>
            </li>
            <li>
              <p>ROAST APPLE AND GOAT</p>
            </li>
            <li>
              <p>CHEESE BRUSCHETTA</p>
            </li>
            <li>
              <p>CHICKEN SATAY W/SESAME</p>
            </li>
            <li>
              <p>LIME SAUCE</p>
            </li>
            <li>
              <p>BEEF SATAY W/ TERIYAKI SAUCE</p>
            </li>
            <li>
              <p>BACON WRAPPED DATES</p>
            </li>
            <li>
              <p>BACON WRAPPED SCALLOPS</p>
            </li>
            <li>
              <p>BACON WRAPPED POTATO</p>
              W/CHEDDAR CHEESE
            </li>
            <li>
              <p>PROSCIUTTO WRAPPED TUNA</p>
            </li>
            <li>
              <p>PROSCIUTTO WRAPPED FIG</p>
              W/MASCARPONE
            </li>
            <li>
              <p>PROSCIUTTO WRAPPED SALMON</p>
            </li>
            <li>
              <p>LAMB CHOP LOLLIPOPS</p>
            </li>
            <li>
              <p>BEEF KABOBS</p>
            </li>
            <li>
              <p>CHICKEN KABOBS</p>
            </li>
            <li>
              <p>TOMATO MOZZARELLA SKEWERS</p>
            </li>
            <li>
              <p>CHEESE TORTELLINI SKEWERS</p>
            </li>
            <li>
              <p>MINI FRITTATAS</p>
            </li>
            <li>
              <p>SAUSAGE STUFFED MUSHROOMS</p>
            </li>
            <li>
              <p>VEGETABLE STUFFED MUSHROOMS</p>
            </li>
            <li>
              <p>CRAB STUFFED MUSHROOMS</p>
            </li>
            <li>
              <p>CHEESE TARTS</p>
            </li>
            <li>
              <p>VEGETABLE TART</p>
            </li>
            <li>
              <p>MINI DUCK AND ASIAGO TART</p>
            </li>
            <li>
              <p>APPLE CIDER GLAZED WINGS</p>
            </li>
            <li>
              <p>SMOKED PORK WINGS</p>
            </li>
            <li>
              <p>TEQUILA LIME SHRIMP COCKTAIL</p>
            </li>
            <li>
              <p>PULLED DUCK QUESADILLA</p>
            </li>
            <li>
              <p>CHEESE QUESADILLA</p>
            </li>
            <li>
              <p>SHREDDED BEEF QUESADILLA</p>
            </li>
            <li>
              <p>CHIPOTLE CHICKEN QUESADILLA</p>
            </li>
            <li>
              <p>SMOKED SALMON MOUSSE</p>
            </li>
            <li>
              <p>TRUFFLE DEVILED EGGS</p>
            </li>
            <li>
              <p>SMOKED SALMON DEVILED EGGS</p>
            </li>
            <li>
              <p>SMOKED BACON AND GREEN ONION</p>
            </li>
            <li>
              <p>OYSTERS ON THE HALF SHELL</p>
            </li>
            <li>
              <p>PARMESAN GRILLED OYSTERS</p>
            </li>
            <li>
              <p>OYSTERS ROCKEFELLER</p>
            </li>
            <li>
              <p>SOFT SHELL CRAB</p>
            </li>
            <li>
              <p>BURGER SUSHI</p>
            </li>
            <li>
              <p>CEVICHÉ</p>
            </li>
            <li>
              <p>SLIDERS</p>
            </li>
            <li>
              <p>FILET</p>
            </li>
            <li>
              <p>CRAB CAKE</p>
            </li>
            <li>
              <p>MEATLOAF</p>
            </li>
            <li>
              <p>GRILLED HONEY CHICKEN</p>
            </li>
            <li>
              <p>BBQ PULLED</p>
            </li>
            <li>
              <p>CITRUS MAHI TACOS</p>
            </li>
            <li>
              <p>ROAST PORK TACOS</p>
            </li>
            <li>
              <p>Braised Short Rib</p>
            </li>
    
            <li>
              <p>Grecian Chicken</p>
            </li>        
    
            <li>
              <p>Baked Salmon</p>
            </li>
    
            <li>
              <p>Carved Tenderloin</p>
            </li>
    
            <li>
              <p>Lamb Chops</p>
            </li>        
    
          </ul> */}

          {/* <button className="read_more" onClick={handleClick}>
              {onClick ? (
                <>Show less</>
              ):(
                <>See More</>
              )}
            </button> */}
          {/* <ul className="menu-type-container">
            <h2>Sides</h2>
            <li>
              <Image filename="mac-n-cheese.jpeg"/>
              <p className="food-title">Mac n' Cheese</p>
            </li>
            <li>
              <p>Grilled Asparagus</p>
            </li>

            <li>
              <p>Green Beans</p>
            </li>
    
            <li>
              <p>Brussels Sprouts</p>
            </li>
    
            <li>
              <p>Garlic Cheddar Mash</p>
            </li>
    
            <li>
              <p>Mini Roast Potatoes</p>
            </li>

            <li>
              <p>Sweet Potato Mash</p>
            </li>
    
          </ul>
          <ul className="menu-type-container">
            <h2>Desserts</h2>
            <li>
              <Image filename="churro.jpg"/>
              <p className="food-title">Churros</p>
            </li>
            <li>
              <p>Vanilla Cheesecake</p>
            </li>
            <li>
              <p>Mini Ricotta Cheese Donuts</p>
            </li>
            <li>
              <p>Peanut Butter Pie</p>
            </li>
            <li>
              <p>Chocolate Flourless Torte</p>
            </li>
            <li>
              <p>Salted Pretzel Brownie</p>
            </li>
            <li>
              <p>Assorted cookies</p>
            </li>
          </ul> */}
        </div>

      </div>
      )
  
    }
  
  export default Menu