import React, {useState, useEffect } from "react"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/testimonial.css"
import firebase from "../components/firebase"

const GetTestimonial = () => {
  const [testimonials, setItems] = useState([]); //useState() hook, sets initial state to an empty array

  useEffect(() => {
  const unsubscribe = firebase
  .firestore()
  .collection("testimonials")
  .where("publish","==",true)
  .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
             id: doc.id,
          ...doc.data(),
        }));
        setItems(listItems)
      })                                                                                                                              
      
      return () => unsubscribe();
    },[]);
  return testimonials;
};


const Tenstimonial = () => {

  const [testimonialUsername, setTestimonialUsername] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialDate, setTestimonialDate] = useState("");

  const addTestimonials = e => {
    
    if (!testimonial || ! testimonialUsername || ! testimonialDate) {
      return;
    }

    e.preventDefault();    

    firebase
    .firestore()
    .collection("testimonials")
    .add({
      username:testimonialUsername,
      date:testimonialDate,
      publish: true,      
      testimonial:testimonial,
    })
    .then(() => setTestimonialUsername(""), setTestimonial(""), setTestimonialDate(""));
    
  };
  
  const testimonials = GetTestimonial();

  return(
    <div className="testimonials-component">
    <SEO title="dark horse catering testimonials" />
      <div className="about-image-wrapper">
      
        <div className="header-name-container">
          <h1 className="header_name testimonial_header_name">Testimonials</h1>
        </div>
        <Image filename="pizza-oven.jpeg"/>

        {/* <img className="testimonial_header_image" src="https://user-images.githubusercontent.com/26069518/98967755-77687a80-24d2-11eb-9157-2985d71833e4.jpeg" /> */}

      </div >
      
      <div className="testimonials-section">
  <div className="testimonials-wrapper">
    {testimonials.map(testimonial => (
      <div className="testimonial-card" key={testimonial.id}>
        <h3 className="testimonial-username">{testimonial.username}</h3>
        <p className="testimonial-text">{testimonial.testimonial}</p>
        <span className="testimonial-date">{testimonial.date}</span>
      </div>
    ))}
  </div>

  <div className="testimonial-form-container">
    <h2 className="form-header">Give us a review</h2>
    <p className="form-subheader">We want to hear from our clients</p>
    <form className="testimonial-form" onSubmit={addTestimonials}>
      <input type="text" name="username" placeholder="Name" onChange={e => setTestimonialUsername(e.currentTarget.value)}/>
      <textarea name="Testimonials" placeholder="Testimonials" onChange={e => setTestimonial(e.currentTarget.value)}></textarea>
      <div className="g-recaptcha" data-sitekey={"AIzaSyDRc2nhXdRd4mLlD6_pXUKKriOUF6019Z0"}></div>
      <button type="submit">Send</button>
    </form>
  </div>
</div>

    </div>
  )
};

export default Tenstimonial