import React ,{ useState, useEffect } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Image from "../components/image"
import Carousel from 'react-bootstrap/Carousel'
import "../assets/blog.css"
import firebase from "../components/firebase"

const useItems = () => {
  const [recipes, setItems] = useState([]); //useState() hook, sets initial state to an empty array

  useEffect(() => {
  const unsubscribe = firebase
  .firestore()
  .collection("recipes")
  .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
             id: doc.id,
          ...doc.data(),
        }));
        setItems(listItems)
      })
      return () => unsubscribe();
    },[]);
  return recipes;
};

const Blog = () => {

  const recipes = useItems();
  const [dropDown, setDropDown] = useState({
    id: null,
    height:"auto"}
  );  
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(previousValue => !previousValue);

  const setCss = (e) => {
    if(e == dropDown.id){
      setDropDown({...dropDown,id:null})    
    }else{
      setDropDown({...dropDown,id:e})
    }
  }

  const dropdownOptions = modal ? 'show_recepie' : '';

  return(
    <div className="blog">
      <SEO title="blog"/>
    <div className="about-image-wrapper">
      
      <div className="header-name-container">
        <h1 className="header_name">Blog</h1>
      </div>

      <Carousel indicators={false} interval={3000} touch={true} wrap={true}>
      
        <Carousel.Item className="carousel-images"> 
          <Image  filename="churro.jpg" />
        </Carousel.Item>

        <Carousel.Item className="carousel-images"> 
          <Image  filename="terrell-customer.jpg" />
        </Carousel.Item>

      </Carousel>
    </div >

      <div className="recepie-wrapper">

        <div className="about-us-statement-container">
          <h1>Our Story</h1>

          <div className="about-us-statement">
            <p>
            Dark horse; a previously less known person or thing that emerges to prominence in a situation, especially in a competition involving multiple rivals. 
            Or a contestant on paper that is unlikely to succeed but still might. 
            
            Since 2012 we've done outdoor festivals,cooking classes, weddings, birthdays, wine dinners, breweries, divorce parties(never knew that was a thing) and all other types of catered events. We love customizing menus , so if you are looking for something creative or outside of the box Chef Terrell is your guy. 
            Whether it's a dessert table for your baby shower or a wild game dinner Dark Horse can take care of all your needs. 
            
            </p>

            <Image filename="terrell-customer.jpg" />

          </div>
          
        </div>
        
        <h1 style={{backgroundColor: "#ffb01b", padding: "25px"}}>Try some of our recipes</h1>

        {recipes.map((recipe,index) =>(
          <div className="recipes-container" key={recipe.id}>
            <div className="card-container">
              <div className="card u-clearfix">
                <div className="recipes-info">
                  <h2 className="card-title">{recipe.recipe_name}</h2>
                  <span className="card-description subtle">{recipe.recipe_description}</span>
              </div>
              <div className={`full-recepie ${dropdownOptions}`} style={recipe.id === dropDown.id ? {height:dropDown.height} : {}}>

                {recipe.ingredients.map((ingredient,index) =>(
                  <p className="ingredients-paragraph" key={ingredient.id}>{index+1}) {ingredient}</p>
                  ))}

                {recipe.directions.map((directions,index) =>(
                  <p key={directions.id}>{directions}</p>
                  ))}
                
              </div>
              <img src={recipe.image_url} className="recipe_image_card" />
            </div>
            {dropDown.id === recipe.id ?
            <button className="read_more" onClick={()=>setCss(recipe.id)}>Close</button>
            :
            <button className="read_more" onClick={()=>setCss(recipe.id)}>Read More</button>
            }
            </div>
          </div>
        ))}

      </div>

    </div>
    )

  }

export default Blog