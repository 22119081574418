import React, {useState, useEffect } from "react"
import Image from "../components/image"
import SEO from "../components/seo"
import "../assets/event.css"
import firebase from "../components/firebase"

const useItems = () => {
  const [events, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
  const unsubscribe = firebase
  .firestore()
  .collection("events")
  .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
             id: doc.id,
          ...doc.data(),
        }));
        setItems(listItems)
      })
      return () => unsubscribe();
    },[]);
  return events;
};

const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  // Create a new date assuming the dateStr is in local time zone
  const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
  return date.toLocaleDateString('en-US', options);
};
const Event = () => {

  const events = useItems();

    return (
      <div className="events-component">
      <SEO title="Events" />
      <div className="about-image-wrapper">
        <div className="header-name-container">
          <h1 className="header_name">Events</h1>
        </div>
        <Image filename="events.jpg"/>
      </div >
      <div className="events_container">
        {events.length > 0 ? (
            <h2 >Our Upcoming Events</h2>
          ):(
            <div className="no-events">
              <h2>No events listed yet.</h2>
              <h2>Stay up to date with us on Instagram</h2>
              <a href="https://www.instagram.com/therealdarkhorse/">
                <Image  filename="instagram.png"/>
              </a>
            </div>
          )}
          <div className="events-container">
                  {events.map((event, index) => (
                      <div className="event-card" key={index}>
                          <div className="card-header">
                              <h2 className="event-name">{event.eventName}</h2>
                          </div>
                          <div className="card-body">
                              <p className="event-info"><strong>When: </strong>{formatDate(event.date)}</p>
                              <p className="event-info"><strong>Where:</strong> {event.where}</p>
                              {event.city &&
                                <p className="event-info"><strong>City:</strong> {event.city}</p>
                              }
                              <p className="event-info"><strong>Details:</strong> {event.additionalComment}</p>
                          </div>
                      </div>
                  ))}
              </div>
      </div>
    </div>
    );
};

export default Event