import React from "react"
import {Router , Route} from "@reach/router"
import AboutUs from "../pages/blog"
import ContactUs from "../pages/contact_us"
import Events from "../pages/events"
import Menu from "../pages/menu"
import Gallary from "../pages/gallery"
import Testimonials from "../pages/testimonials"
import Home from "../pages/index"
import Payment from "../pages/coursepayment"

const IndexPage = () => (
    
      <Router>

        <Home exact path="/" />
        <AboutUs path="blog" />
        <ContactUs path="/contact_us" />
        <Events path="events" />
        <Menu path="menu" />
        <Gallary path="gallery" />
        <Testimonials path="testimonials" />
        <Payment path="payment" />

      </Router>
  
  )

  export default IndexPage
  